<div class="container">
	<div class="container-categories">
	  <div class="header">
		<h1 class="title">{{'SUB_SUBCATEGORIES' | translate}}</h1>
		<button *ngIf="!showCreateSubSubCategoryModal" class="btn btn-outline-primary primary-btn" (click)="openCreateSubSubCategoryModal()">{{'NEW' | translate}}</button>
		<button *ngIf="showCreateSubSubCategoryModal" class="btn btn-primary remove-btn" (click)="closeCreateSubSubCategoryModal()">{{'CANCEL' | translate}}</button>
	</div>
	<app-create-sub-subcategory *ngIf="showCreateSubSubCategoryModal" (subSubCategoryCreated)="handleSubSubCategoryCreated()"[categoryId]="categoryId"></app-create-sub-subcategory>
	  <div class="form-card">
		<div class="search-container visually-hidden">
		  <input
			type="text"
			placeholder="Buscar"
			[(ngModel)]="searchString"
			(keyup)="searchSubSubCategories()"
		  />
		</div>
		<div class="loading" *ngIf="loading">
		  <div class="spinner-border" role="status"></div>
		</div>
		<table *ngIf="!loading" class="table table-bordered table-hover">
		  <thead>
			<th scope="col" class="table-start">Id</th>
			<th scope="col">{{'SUB_SUBCATEGORY' | translate}}</th>
			<th scope="col">{{ 'RESOURCE' | translate }}</th>
			<th scope="col">{{'IMAGE' | translate}}</th>
			<th scope="col">{{'SUBCATEGORY' | translate}}</th>
			<th scope="col">{{'CATEGORY' | translate}}</th>
			<th scope="col">{{'EDIT' | translate}}</th>
			<th scope="col" class="table-end">{{'DELETE' | translate}}</th>
		  </thead>
		  <tbody>
			<tr *ngFor="let subSubCategory of subSubCategories">
			  <ng-container *ngIf="subSubCategory.categoryId === categoryId">
				<td>{{ subSubCategory.subSubCategoryId }}</td>
				<td>{{ subSubCategory.subSubCategoryName }}</td>
				<td>{{ subSubCategory.resourceName }}</td>
				<td>
					<img class="image-box" *ngIf="subSubCategory.subSubCategoryImageUrl" [src]="subSubCategory.subSubCategoryImageUrl" alt="subSubCategoryImageUrl">
					<span *ngIf="!subSubCategory.subSubCategoryImageUrl">Sin Imagen</span>
				</td>
				<td>{{ subSubCategory.subCategoryName }}</td>
				<td>{{ subSubCategory.categoryName }}</td>
				<td>
					<button class="btn btn-primary primary-btn" (click)="openEditSubCategoryModal(subSubCategory.subSubCategoryId)">
						{{'EDIT' | translate}}
					  </button>
				</td>
				<td>
				  <button class="btn btn-primary remove-btn" (click)="modalDelete = true; subSubCategoryIdToDelete = subSubCategory.subSubCategoryId">
					{{'DELETE' | translate}}
				  </button>
				</td>
			  </ng-container>
			</tr>
		  </tbody>
		</table>
	  </div>
	</div>

	<app-confirmation-modal
	  *ngIf="modalDelete"
	  [title]="'CONFIRM_DELETE_SUB_SUBCATEGORY' | translate"
    [message]="'DELETE_SUB_SUBCATEGORY_WARNING' | translate"
    [confirmText]="'DELETE' | translate"
    [cancelText]="'CANCEL' | translate"
	  (response)="deleteSubSubCategory($event)">
	</app-confirmation-modal>

	<section class="modal-section" *ngIf="showEditSubSubCategoryModal">
		<div class="modal-content">
		  <div class="modal-body">
			<app-edit-sub-subcategory *ngIf="showEditSubSubCategoryModal" [categoryId]="selectedCategoryId" (subSubCategoryUpdated)="handleSubSubCategoryUpdated()" [id]="selectedSubSubCategoryId"></app-edit-sub-subcategory>
			<div class="modal-btn">
			  <button class="btn btn-primary remove-btn" (click)="closeEditSubSubCategoryModal()">{{'CANCEL' | translate}}</button>
			</div>
		  </div>
		</div>
	  </section>

	<div class="modal delete-category-confirm-modal" id="deleteSubSubCategoryModal" tabindex="-1">
	  <div class="modal-dialog category-modal-dialog" role="document">
		<div class="modal-content category-modal-content">
		  <div class="modal-body category-modal-body">
			<h3 class="text-center">{{'SUCCESS' | translate}}!</h3>
			<p class="text-center">{{ 'SUB_SUBCATEGORY_DELETED_SUCCESS' | translate }}</p>
		  </div>
		  <div class="modal-buttons">
			<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
		  </div>
		</div>
	  </div>
	</div>
  </div>
