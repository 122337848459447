import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ShopTheming, ThemingService } from 'src/app/theming.service';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
declare let $: any;

@Component({
  selector: 'app-edit-component',
  templateUrl: './edit-component.component.html',
  styleUrls: ['./edit-component.component.scss']
})
export class EditComponentComponent implements OnInit {
  @Input() componentId!: number;
  @Output() close = new EventEmitter<void>();
  public shopTheme: ShopTheming[] = [];
  loading: boolean = false;
  timestamp: number;
  selectedParentId: number = 0;
  deletedInfoIds: number[] = [];  
  minItems: number = 0;
  maxItems: number = 0;
  errorMessage: string = '';
  collections: any [] = [];
  categories: any [] = [];
  subCategories: any [] = [];
  subSubCategories: any [] = [];
  componentTypes: any[] = [];
  selectedOption: any;
  componentParentId: any;
  categoryId: number = 0;
  subCategoryId: number = 0;
  subSubCategoryId: number = 0;
  collectionId: number = 0;
  productComponentType: number = 0;
  descriptionText: string = '';
  textLength: number = 0; 
  maxLength: number = 2500; 
  componentForm!: FormGroup;
  remainingCharacters: number = this.maxLength; 
  infoMaxLength: number = 2500;
  remainingInfoCharacters: number = this.infoMaxLength;

  component: any = {
    componentImage: '',
    componentId: 0,
    status: 0,
    position: 0,
    itemQuantity: 0,
    infoImageUrl: '',
    title: '',
    subtitle: '',
    redirectUrl: '',
    infoDescription: '',
    description: '',
    infoIds: '',
    infoTitles: '',
    urlNames: '',
    componentInfo: []
  };
 
  modules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons //['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{size: ['small', false, 'large', 'huge'],},], // custom dropdown
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['link'], 
      ['emoji'],
      ['clean'], // remove formatting button
    ],
  };
  
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private themingService: ThemingService,
    public appComponent: AppComponent,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    this.timestamp = Date.now();
    this.route.paramMap.subscribe((params) => {
      let componentId = Number(params.get('componentId'));
      if (componentId !== this.componentId) {
        this.appComponent.goToTop();
        this.componentId = componentId;
      }
    });
  }
  
  fieldVisibilityConfig: { [key: number]: string[] } = {
    1: ['infoImageUrl', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'],
    2: ['infoImageUrl', 'infoDescription', 'redirectUrl'],
    3: ['infoImageUrl', 'title', 'infoTitle', 'infoDescription', 'redirectUrl'],
    4: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl'],
    5: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl'],
    6: ['title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl'],
    7: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'],
    8: ['title', 'subtitle', 'itemQuantity'],
    9: ['title', 'subtitle', 'itemQuantity'],
    10: ['title', 'subtitle', 'itemQuantity'],
    11: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'],
    12: ['infoImageUrl', 'redirectUrl'],
    13: ['title', 'subtitle', 'description', 'redirectUrl'],
    14: ['title', 'subtitle', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'],
    15: ['infoImageUrl', 'redirectUrl', 'urlName'],
    16: ['infoImageUrl', 'redirectUrl'],
  };
  
  getFieldVisibilityConfig(): string[] {
    return this.fieldVisibilityConfig[this.component.componentTypeId] || [];
  }
  
  ngOnInit(): void {
    this.loading = true;
    Promise.all([
      this.getComponentById(this.componentId),
      this.getCollections(),
      this.getCategories(),
      this.getSubCategories(),
      this.getSubSubCategories(),
      this.getComponentTypes()
    ]).then(() => {
      this.loading = false;
      if (this.component?.description) {
        this.descriptionText = this.sanitizer.bypassSecurityTrustHtml(this.component.description) as string;
      }
    }).catch((error) => {
      console.error(error);
      this.loading = false;
    });
  }
  
  async getComponentById(id: number): Promise<void> {
    try {
      const data: any = await this.apiService.getComponentById(id, this.themingService.shopId);
      this.component = JSON.parse(data.body); 
      this.productComponentType = this.component.productComponentType;
      this.component.itemQuantity = this.component.itemQuantity;
      this.component.infoIds = this.component.componentInfo.map((info: any) => info.infoId).join('|');
      this.component.componentInfo.forEach((info: any) => {
        info.selected = false;
      });
      this.minItems = this.component.minItems;
      this.maxItems = this.component.maxItems;
      if (this.component.componentParentId == 3) {
        if (this.component.collectionId) {
          this.selectedOption = 'collections';
          this.collectionId = this.component.collectionId;
        } else if (this.component.categoryId) {
          this.selectedOption = 'categories';
          this.categoryId = this.component.categoryId;
        } else if (this.component.subCategoryId) {
          this.selectedOption = 'subCategories';
          this.subCategoryId = this.component.subCategoryId;
        } else if (this.component.subSubCategoryId) {
          this.selectedOption = 'subSubcategories';
          this.subSubCategoryId = this.component.subSubCategoryId;
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getComponentTypes(): Promise<void> {
    try {
      const response: any = await this.apiService.getComponentTypes(this.themingService.shopId);
      this.componentTypes = response.data.filter((type: any) => 
        type.componentParentId === 3 && [8, 9, 10].includes(type.componentTypeId)
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  onComponentTypeSelected(componentTypeId: number): void {
    this.component.componentTypeId = componentTypeId;
  }


  async getCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getCategories(this.themingService.shopId);
      this.categories = data.categories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getCollections(): Promise<void> {
    try {
      const data: any = await this.apiService.getCollections(this.themingService.shopId);
      this.collections = data.collections;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubCategories(this.themingService.shopId);
      this.subCategories = data.subCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getSubSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubSubCategories(this.themingService.shopId);
      this.subSubCategories = data.subSubCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
     
  addNewField() {
    if (this.component.componentInfo.length >= this.maxItems) {
    this.errorMessage = `El máximo de items para este componente es de ${this.maxItems}.`;
    $('#errorEditMaxMinItemModal').modal('show');
    } else {
    const newInfoId = this.generateNewInfoId();
    this.component.componentInfo.push({
      infoId: newInfoId,
      infoDescription: '',
      infoImageUrl: '',
      redirectUrl: '',
      infoTitle: '',
      urlName: '',
      selected: false
    });
    this.errorMessage = ''; 
  }
  }

  onItemQuantityChange(event: any) {
    this.component.itemQuantity = event.target.value;
 }
  
  removeField(index: number) {
    if (this.component.componentInfo.length <= this.minItems) {
      this.errorMessage = `El mínimo de items para este componente es de ${this.minItems}.`;
      $('#errorEditMaxMinItemModal').modal('show');
    } else {
      const infoId = this.component.componentInfo[index].infoId;
      if (infoId > 0) {
        this.deletedInfoIds.push(infoId);
      }
      this.component.componentInfo.splice(index, 1);
      this.errorMessage = ''; 
    }
  }
  
  onOptionChange(option: string) {
    this.selectedOption = option;
    this.resetSelections();
    switch (option) {
      case 'categories':
        this.productComponentType = 1;
        break;
      case 'subCategories':
        this.productComponentType = 2;
        break;
      case 'subSubcategories':
        this.productComponentType = 3;
        break;
      case 'collections':
        this.productComponentType = 4;
        break;
      default:
        this.productComponentType = this.productComponentType || 0;
        break;
    }
  }
  
  onCategoryChange(event: any) {
    this.categoryId = event.target.value;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;
  }

  onSubCategoryChange(event: any) {
    this.subCategoryId = event.target.value;
    this.subSubCategoryId = 0;
    this.collectionId = 0;
  }

  onSubSubCategoryChange(event: any) {
    this.subSubCategoryId = event.target.value;
    this.collectionId = 0;
  }

  onCollectionChange(event: any) {
    this.collectionId = event.target.value;
    this.categoryId = 0;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
  }

  resetSelections() {
    this.categoryId = 0;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;
  }

  deleteImage(index: number) {
    this.removeField(index);
  }

  closeComponent() {
    this.close.emit();
  }

  descriptionChanged(event: any) {
    if (event.event === 'text-change') {
      const quill = event.editor;
      this.textLength = quill.getLength() - 1;
      this.remainingCharacters = this.maxLength - this.textLength; 
      if (this.textLength > this.maxLength) {
        quill.deleteText(this.maxLength, this.textLength - this.maxLength);
        this.remainingCharacters = 0; 
      } else {
        const description = event.html;
        this.componentForm.get('component.description')?.setValue(description);  
      }
    }
  }
  
  changeComponentInfoDescription(event: any, index: number): void {
    if (event.event === 'text-change') {
      const quill = event.editor;
      const infoTextLength = quill.getLength() - 1; 
      this.remainingInfoCharacters = this.infoMaxLength - infoTextLength;
      if (infoTextLength > this.infoMaxLength) {
        quill.deleteText(this.infoMaxLength, infoTextLength - this.infoMaxLength);
        this.remainingInfoCharacters = 0; 
      }
    }
  }

  changeComponentTitle(event: string) {
    this.component.title = event;
  }

  changeComponentName(event: string) {
    this.component.componentName = event;
  }


  changeComponentSubtitle(event: string) {
    this.component.subtitle = event;
  }

  changeComponentDescription(event: string) {
    this.component.description = event;
  }

  changeComponentRedirectUrl(event: string, index: number) {
    this.component.componentInfo[index].redirectUrl = event;
  }

  changeComponentInfoTitle(event: string, index: number) {
    this.component.componentInfo[index].infoTitle = event;
  }

  changeComponentUrlName(event: string, index: number) {
    this.component.componentInfo[index].urlName = event;
  }

  sanitizeImageURL(imageURL: string) {
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }

  triggerFileInput(index: number | null = null): void {
    const uploadButton = index !== null ? document.getElementById(`btn-upload-${index}`) : document.getElementById('btn-upload');
    if (uploadButton) {
      uploadButton.click();
    }
  }

  onFileSelected(event: any, index: number | null = null): void {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      const image: File = files[0];
      if (image && image.type.startsWith('image')) {
        this.uploadImage(image)
          .then((uploadedImage) => {
            console.log('Image uploaded successfully:', uploadedImage);
            if (index !== null) {
              this.component.componentInfo[index].infoImageUrl = uploadedImage.imageURL;
              this.component.componentInfo[index].blob = uploadedImage.blob;
              this.component.componentInfo[index].selected = true;
            } else {
              const newInfoId = this.generateNewInfoId();
              this.component.componentInfo = [
                ...this.component.componentInfo, // Ensure existing data is not overwritten
                {
                  infoId: newInfoId,
                  infoDescription: '',
                  infoImageUrl: uploadedImage.imageURL,
                  redirectUrl: '',
                  infoTitle: '',
                  urlName: '',
                  selected: true
                }
              ];
            }
          })
          .catch((error) => {
            console.log('Error uploading image:', error);
          });
      } else {
        console.log('Not an image');
      }
    }
  }

  generateNewInfoId(): number {
    return -1 * Date.now();  // Generate a negative timestamp as a temporary ID
  }

  blobFile = async ($event: any): Promise<any> => {
    try {
      const unSafeImg = window.URL.createObjectURL($event);
      const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            blob: $event,
            img,
            base: reader.result
          });
        };
        reader.onerror = (error) => {
          reject({
            blob: $event,
            img,
            base: null
          });
        };
        reader.readAsDataURL($event);
      });
    } catch (e) {
      return Promise.reject(null);
    }
  };

  uploadImage(image: File): Promise<any> {
    return this.blobFile(image)
      .then((res: any) => {
        const timestamp = Date.now();
        const ext = image.name.split('.').pop();
        const key = `${this.themingService.shopId}/${environment.stage}/images/components/component${timestamp}.${ext}`;
        const existingImage = this.component.componentInfo.find((info: { infoImageUrl: string | string[]; }) => info.infoImageUrl.includes(key));
        if (existingImage) {
          return Promise.resolve({
            imageURL: existingImage.infoImageUrl,
            blob: res.blob,
          });
        }
        return this.apiService.uploadFile(key, res.blob, { position: 1, type: 'original' })
          .then((data: any) => {
            return {
              imageURL: data.imageURL,
              blob: res.blob,
            };
          });
      });
  }

  async updateComponent(): Promise<void> {
    try {
      const uploadPromises = this.component.componentInfo
        .filter((info: any) => info.blob)
        .map((info: any) => {
          const ext = info.infoImageUrl.split('.').pop();
          const timestamp = Date.now();
          const key = `${this.themingService.shopId}/${environment.stage}/images/components/component${timestamp}.${ext}`;
          const existingImage = this.component.componentInfo.find((existingInfo: { infoImageUrl: string | string[]; }) => existingInfo.infoImageUrl.includes(key));
          if (existingImage) {
            info.infoImageUrl = existingImage.infoImageUrl;
            return Promise.resolve();
          }
          return this.apiService.uploadFile(key, info.blob, {})
            .then((data: any) => {
              info.infoImageUrl = data.imageURL;
            });
        });

      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  }

  async validateFormData(): Promise<void> {
    this.loading = true;
    try {
      await this.updateComponent();
  
      let imageUrls = '';
      let descriptions = '';
      let redirectUrls = '';
      let infoIds = '';
      let infoTitles = '';
      let urlNames = '';
  
      imageUrls = this.component.componentInfo.map((info: any) => info.infoImageUrl || '').join('|');
      descriptions = this.component.componentInfo
        .map((info: any) => this.sanitizeString(info.infoDescription || ''))
        .join('|');
      redirectUrls = this.component.componentInfo
        .map((info: any) => this.sanitizeString(info.redirectUrl || ''))
        .join('|');
      infoIds = this.component.componentInfo.map((info: any) => info.infoId.toString() || '').join('|');
      infoTitles = this.component.componentInfo
        .map((info: any) => this.sanitizeString(info.infoTitle || ''))
        .join('|');
      urlNames = this.component.componentInfo
        .map((info: any) => this.sanitizeString(info.urlName || ''))
        .join('|');
  
      const productComponentType = this.productComponentType || this.component.productComponentType;
  
      await this.apiService.updateComponent(
        this.component.componentId,
        this.themingService.shopId,
        this.sanitizeString(this.component.title),         
        this.sanitizeString(this.component.subtitle),      
        this.sanitizeString(this.component.description),   
        this.component.itemQuantity,
        this.component.itemListId,
        this.component.itemSort,
        imageUrls,
        descriptions,
        redirectUrls,
        infoIds,
        this.deletedInfoIds.join('|'),                    
        infoTitles,
        urlNames,
        this.collectionId,
        this.categoryId,
        this.subCategoryId,
        this.subSubCategoryId,
        productComponentType,
        this.component.componentTypeId,
        this.sanitizeString(this.component.componentName),         
      ).then((data: any) => {
        this.loading = false;
        if (data.statusCode === 200) {
          $('#successEditComponentModal').modal('show');
          this.getComponentById(this.componentId);  
        } else {
          $('#errorEditComponentModal').modal('show');
        }
      }).catch((error: any) => {
        console.error(error);
        this.loading = false;
        $('#errorEditComponentModal').modal('show');
      });
    } catch (error) {
      console.error(error);
      this.loading = false;
      $('#errorEditComponentModal').modal('show');
    }
  }
  
  
  sanitizeString(str: string): string {
    if (!str) return '';
    return str
      .replace(/\\/g, '\\\\')   
      .replace(/"/g, '\\"')     
      .replace(/\n/g, '\\n')    
      .replace(/\r/g, '\\r');   
  }
  
  closeModal(): void {
    $('#successEditComponentModal').modal('hide');
    $('#errorEditComponentModal').modal('hide');
    $('#errorEditMaxMinItemModal').modal('hide');
  }
}