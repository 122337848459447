import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-create-component',
  templateUrl: './create-component.component.html',
  styleUrls: ['./create-component.component.scss']
})
export class CreateComponentComponent implements OnInit {
@Output() creationSuccess = new EventEmitter<void>();
  componentForm: FormGroup;
  componentTypes: any[] = [];
  loading: boolean = false;
  selectedParentId: number = 0;
  groupedDesigns: any;
  uniqueParentIds: any[] = [];
  selectedDesignDescription: string = '';
  selectedDesignImageUrl: string = '';
  selectedDesign: any = null;
  minItems: number = 0;
  maxItems: number = 0;
  errorMessage: string = '';
  categories: any[] = [];
  collections: any[] = [];
  subCategories: any[] = [];
  subSubCategories: any[] = [];
  selectedOption: string = '';
  collectionId:  number = 0;
  categoryId:  number = 0;
  subCategoryId:  number = 0;
  subSubCategoryId:  number = 0;
  productComponentType: number = 0;
  designEnabled: boolean = false;
  itemQuantity: number = 4;
  componentName: any;
  descriptionText: any;
  infoDescriptionText: string = ''; 
  infoTextLength: number = 0;  
  infoMaxLength: number = 2500;  
  maxLength = 2500;
  textLength = 0;
  remainingCharacters: number = 0;
  remainingInfoCharacters: number = 0; 

  parentIdNames: { [key: number]: string } = {
    1: 'Slider',
    2: 'Tarjetas',
    3: 'Productos'
  };

  fieldVisibilityConfig: { [key: number]: string[] } = {
    1: ['infoImageUrl', 'infoTitle', 'infoDescription','redirectUrl', 'urlName'],
    2: ['infoImageUrl', 'infoDescription','redirectUrl'],
    3: ['title', 'infoImageUrl', 'infoTitle', 'infoDescription', 'redirectUrl'],
    4: ['title', 'description', 'infoImageUrl', 'infoTitle', 'infoDescription', 'redirectUrl'],
    5: ['title', 'description', 'infoImageUrl', 'infoTitle', 'infoDescription', 'redirectUrl'],
    6: ['title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl'],
    7: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'],
    8: ['title', 'subtitle', 'itemQuantity'],
    9: ['title', 'subtitle', 'itemQuantity'],
    10: ['title', 'subtitle', 'itemQuantity'],
    11: ['infoImageUrl', 'title', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'],
    12: ['infoImageUrl', 'redirectUrl'],
    13: ['title', 'subtitle', 'description', 'redirectUrl'],
    14: ['title', 'subtitle', 'description', 'infoTitle', 'infoDescription', 'redirectUrl', 'urlName'],
    15: ['infoImageUrl', 'redirectUrl', 'urlName'],
    16: ['infoImageUrl', 'redirectUrl'],
  };

  modules = {
    'emoji-shortname': true,
    'emoji-textarea': true,
    'emoji-toolbar': true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons //['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'],},], // custom dropdown
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['link'], 
      ['emoji'],
      ['clean'], // remove formatting button
    ],
  };
  
  constructor(
    public appComponent: AppComponent,
    private fb: FormBuilder,
    private apiService: ApiService,
    private themingService: ThemingService,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {
    this.componentForm = this.fb.group({
      component: this.fb.group({
        componentId: [0],
        title: [''],
        subtitle: [''],
        description: [''],
        infoTitle: [''],
        infoDescription: [''],
        redirectUrl: [''],
        urlName: [''],
        componentTypeId: [0, Validators.required],
        status: [0],
        position: [0],
        itemQuantity: [0],
        itemListId: [0],
        itemSort: [0],
        categoryId: [0],
        subCategoryId: [0],
        subSubCategoryId: [0],
        collectionId: [0]
      }),
      infoFields: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.loading = true;
    Promise.all([
      this.getComponentTypes(),
      this.getCategories(),
      this.getCollections(),
      this.getSubCategories(),
      this.getSubSubCategories()
    ]).then(() => {
      this.loading = false;
    }).catch((error) => {
      console.error(error);
      this.loading = false;
    });
  }
  
  async getComponentTypes(): Promise<void> {
    try {
      const response: any = await this.apiService.getComponentTypes(this.themingService.shopId);
      this.componentTypes = response.data;
      this.uniqueParentIds = this.getUniqueParentIds();
      this.groupedDesigns = this.getDesignsByParentId();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getCategories(this.themingService.shopId);
      this.categories = data.categories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getCollections(): Promise<void> {
    try {
      const data: any = await this.apiService.getCollections(this.themingService.shopId);
      this.collections = data.collections;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubCategories(this.themingService.shopId);
      this.subCategories = data.subCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  async getSubSubCategories(): Promise<void> {
    try {
      const data: any = await this.apiService.getSubSubCategories(this.themingService.shopId);
      this.subSubCategories = data.subSubCategories;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  
  onOptionChange(option: string) {
    this.selectedOption = option;
    this.resetSelections();

    switch (option) {
      case 'categories':
        this.productComponentType = 1;
        break;
      case 'subCategories':
        this.productComponentType = 2;
        break;
      case 'subSubcategories':
        this.productComponentType = 3;
        break;
      case 'collections':
        this.productComponentType = 4;
        break;
      case 'custom':
        this.productComponentType = 5;
        break;
    }
  }

descriptionChanged(event: any) {
  if (event.event === 'text-change') {
    const quill = event.editor;
    this.textLength = quill.getLength() - 1;  
    this.remainingCharacters = this.maxLength - this.textLength; 

    if (this.textLength > this.maxLength) {
      quill.deleteText(this.maxLength, this.textLength - this.maxLength);
      this.remainingCharacters = 0; 
    } else {
      const description = event.html;
      this.componentForm.get('description')?.setValue(description);
      this.cdr.detectChanges();
    }
  }
}
  
  infoDescriptionChanged(event: any) {
    if (event.event === 'text-change') {
      const quill = event.editor;
      this.infoTextLength = quill.getLength() - 1; 
      this.remainingInfoCharacters = this.infoMaxLength - this.infoTextLength; 
      if (this.infoTextLength > this.infoMaxLength) {
        quill.deleteText(this.infoMaxLength, this.infoTextLength - this.infoMaxLength);
        this.remainingInfoCharacters = 0;  
      } else {
        const infoDescription = event.html;
        this.componentForm.get('infoDescription')?.setValue(infoDescription);
        this.cdr.detectChanges();
      }
    }
  }

  onItemQuantityChange(event: any) {
    this.itemQuantity = event.target.value;
 }
    
  onCategoryChange(event: any) {
    this.categoryId = event.target.value;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;
    this.designEnabled = true; 
 }
    
  onSubCategoryChange(event: any) {
      this.subCategoryId = event.target.value;
      this.subSubCategoryId = 0;
      this.collectionId = 0;
      this.designEnabled = true; 
  }
    
  onSubSubCategoryChange(event: any) {
      this.subSubCategoryId = event.target.value;
      this.collectionId = 0;
      this.designEnabled = true; 
  }
    
  onCollectionChange(event: any) {
      this.collectionId = event.target.value;
      this.categoryId = 0;
      this.subCategoryId = 0;
      this.subSubCategoryId = 0;
      this.designEnabled = true; 
  }
    
    
  resetSelections() {
    this.categoryId = 0;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;
    this.designEnabled = false;
  }

  onChangeParentId(event: any) {
    this.selectedParentId = event.target.value;
    this.resetForm(false);
    this.componentForm.get('component.componentTypeId')?.setValue(0);
    this.designEnabled = this.selectedParentId != 3;
  }

  getUniqueParentIds() {
    const uniqueParentIds = Array.from(new Set(this.componentTypes.map(item => item.componentParentId)));
    return uniqueParentIds
    .filter(parentId => this.parentIdNames.hasOwnProperty(parentId))
    .map(parentId => {
      const firstComponentWithType = this.componentTypes.find(item => item.componentParentId === parentId);
      return { id: parentId, name: firstComponentWithType ? this.parentIdNames[parentId] : '' };
    });
  }
  
  private getDesignsByParentId(): any {
    return this.componentTypes.reduce((acc, curr) => {
      if (!acc[curr.componentParentId]) {
        acc[curr.componentParentId] = [];
      }
      acc[curr.componentParentId].push({ id: curr.componentTypeDesignId, name: curr.componentTypeName, typeId: curr.componentTypeId, description: curr.componentTypeDescription, imageUrl: curr.imageUrl });
      return acc;
    }, {});
  }

  changeComponentTypeId(event: any) {
    const componentTypeId = parseInt(event.target.value, 10);
    this.componentForm.get('component.componentTypeId')?.setValue(componentTypeId);
    
    this.selectedDesign = this.groupedDesigns[this.selectedParentId]?.find((design: { typeId: any; }) => design.typeId === componentTypeId);
    if (this.selectedDesign) {
      this.selectedDesignDescription = this.selectedDesign.description;
      this.selectedDesignImageUrl = this.selectedDesign.imageUrl;
    }
    
    const componentType = this.componentTypes.find(ct => ct.componentTypeId === componentTypeId);
    if (componentType) {
      this.minItems = componentType.minItems;
      this.maxItems = componentType.maxItems;

      this.infoFields.clear();
      for (let i = 0; i < this.minItems; i++) {
        this.addNewField(false); 
      }
    } else {
      this.errorMessage = 'No se pudo encontrar el tipo de componente seleccionado.';
    }
  }
  
  getFieldVisibilityConfig(): string[] {
    return this.fieldVisibilityConfig[this.componentForm.get('component.componentTypeId')?.value] || [];
  }

  get infoFields(): FormArray {
    return this.componentForm.get('infoFields') as FormArray;
  }

  setDynamicValidations(componentTypeId: number) {
    const visibilityConfig = this.fieldVisibilityConfig[componentTypeId] || [];
    const componentControls = this.componentForm.get('component') as FormGroup;
  
    Object.keys(componentControls.controls).forEach(key => {
      componentControls.get(key)?.clearValidators();
      componentControls.get(key)?.updateValueAndValidity();
    });

    visibilityConfig.forEach(field => {
      componentControls.get(field)?.setValidators([Validators.required]);
      componentControls.get(field)?.updateValueAndValidity();
    });
  }
  addNewField(validate: boolean = true) {
    const visibilityConfig = this.getFieldVisibilityConfig();
    const newField = this.fb.group({});
  
    if (visibilityConfig.includes('infoTitle')) {
      newField.addControl('infoTitle', this.fb.control('', Validators.required));
    } else {
      newField.addControl('infoTitle', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('infoDescription')) {
      newField.addControl('infoDescription', this.fb.control('', Validators.required));
    } else {
      newField.addControl('infoDescription', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('redirectUrl')) {
      newField.addControl('redirectUrl', this.fb.control('', Validators.required));
    } else {
      newField.addControl('redirectUrl', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('urlName')) {
      newField.addControl('urlName', this.fb.control('', Validators.required));
    } else {
      newField.addControl('urlName', this.fb.control(''));
    }
  
    if (visibilityConfig.includes('infoImageUrl')) {
      newField.addControl('imageURL', this.fb.control('', Validators.required));
    } else {
      newField.addControl('imageURL', this.fb.control(''));
    }
  
    if (validate && this.infoFields.length >= this.maxItems) {
      this.errorMessage = `El máximo de items para este componente es de ${this.maxItems}.`;
      $('#errorMaxMinItemModal').modal('show');
    } else {
      this.infoFields.push(newField);
      this.cdr.detectChanges();
      this.errorMessage = ''; 
    }
  }
      
  removeField(index: number) {
    if (this.infoFields.length <= this.minItems) {
      this.errorMessage = `El mínimo de items para este componente es de ${this.minItems}.`;
      $('#errorMaxMinItemModal').modal('show');
    } else {
      this.infoFields.removeAt(index);
      this.errorMessage = ''; 
    }
  }
  

   handleInfoFieldChange(index: number, field: 'infoTitle' | 'infoDescription' | 'redirectUrl' | 'imageURL' | 'urlName', value: string) {
    this.infoFields.at(index).get(field)?.setValue(value);
  }
              
  handleInfoImageChange(index: number, event: any) {
    const file: File = event.target.files[0];
    if (file && file.type.startsWith('image')) {
      this.uploadImage(file)
        .then((uploadedImage) => {
          this.infoFields.at(index).get('imageURL')?.setValue(uploadedImage.imageURL);
          this.cdr.detectChanges();
        })
        .catch((error) => {
          console.log('Error uploading image:', error);
        });
    } else {
      console.log('Not an image');
    }
  }

  uploadImage(image: File): Promise<any> {
    return this.blobFile(image)
      .then((res: any) => {
        const timestamp = Date.now();
        const ext = image.name.split('.').pop();
        const key = `${this.themingService.shopId}/${environment.stage}/images/components/component${timestamp}.${ext}`;
        return this.apiService.uploadFile(key, res.blob, { position: 1, type: 'original' })
          .then((data: any) => {
            return {
              imageURL: data.imageURL,
              blob: res.blob,
            };
          });
      });
  }

  blobFile = async ($event: any): Promise<any> => {
    try {
      const unSafeImg = window.URL.createObjectURL($event);
      const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            blob: $event,
            img,
            base: reader.result
          });
        };
        reader.onerror = (error) => {
          reject({
            blob: $event,
            img,
            base: null
          });
        };
        reader.readAsDataURL($event);
      });
    } catch (e) {
      return Promise.reject(null);
    }
  };

  sanitizeImageURL(imageURL: string) {
    return this.sanitizer.bypassSecurityTrustUrl(imageURL);
  }

  triggerFileInput(index?: number): void {
    const uploadButton = document.getElementById(index !== undefined ? `btn-upload-${index}` : 'btn-upload') as HTMLInputElement;
    if (uploadButton) {
      uploadButton.click();
    }
  }

  async validateFormData(): Promise<void> {
    this.loading = true;

    if (!this.componentForm.get('component.componentTypeId')?.value) {
      this.errorMessage = 'Por favor, seleccione un diseño antes de crear el componente.';
      $('#errorMaxMinItemModal').modal('show');
      this.loading = false;
      return;
    }

    if (!this.componentName) {
      this.errorMessage = 'Por favor, ingrese un nombre para el componente.';
      $('#errorMaxMinItemModal').modal('show');
      this.loading = false;
      return;
    }
  
    try {

      const componentData = this.componentForm.value;
      let combinedImageUrl = '';
      let descriptions = '';
      let redirectUrls = '';
      let infoTitles = '';
      let urlNames = '';

      if (componentData.infoFields.length > 0) {
        const imageUrls = await this.createComponentImages();
        combinedImageUrl = imageUrls.join('|');
        
        descriptions = componentData.infoFields
          .map((field: any) => this.sanitizeString(field.infoDescription))
          .join('|');
        redirectUrls = componentData.infoFields
          .map((field: any) => this.sanitizeString(field.redirectUrl))
          .join('|');
        infoTitles = componentData.infoFields
          .map((field: any) => this.sanitizeString(field.infoTitle))
          .join('|');
        urlNames = componentData.infoFields
          .map((field: any) => this.sanitizeString(field.urlName))
          .join('|');
      }
  
      
      await this.apiService.createComponent(
        this.componentForm.get('component.componentId')?.value,  
        this.themingService.shopId,         
        this.sanitizeString(this.componentName),                     
        this.sanitizeString(componentData.component.title), 
        this.sanitizeString(componentData.component.subtitle), 
        componentData.component.position,                    
        componentData.component.status,                    
        this.sanitizeString(componentData.component.description),
        componentData.component.componentTypeId,                 
        this.itemQuantity,                                       
        componentData.component.itemListId,                     
        componentData.component.itemSort,                        
        combinedImageUrl,                                       
        descriptions,                                            
        redirectUrls,                                           
        infoTitles,                                              
        urlNames,                                                
        this.collectionId,                                      
        this.categoryId,                                         
        this.subCategoryId,                                     
        this.subSubCategoryId,                                   
        this.productComponentType                                
      ).then((data: any) => {
        this.loading = false;
        if (data.statusCode === 200) {
          $('#successcomponentModal').modal('show');
          $('#successcomponentModal').on('hidden.bs.modal', () => {
            this.creationSuccess.emit();
          });
        } else {
          $('#errorcomponentModal').modal('show');
        }
      }).catch((error: any) => {
        console.error(error);
        this.loading = false;
        $('#errorcomponentModal').modal('show');
      });
    } catch (error) {
      console.error(error);
      this.loading = false;
      $('#errorcomponentModal').modal('show');
    }
  }
  
  sanitizeString(str: string): string {
    if (!str) return '';
    return str
      .replace(/\\/g, '\\\\')   
      .replace(/"/g, '\\"')     
      .replace(/\n/g, '\\n')    
      .replace(/\r/g, '\\r');   
  }
  

  async createComponentImages(): Promise<string[]> {
    const uploadedImages = [];
    for (const field of this.infoFields.controls) {
      const fieldGroup = field as FormGroup;
      if (fieldGroup.get('imageURL')?.value) {
        uploadedImages.push(fieldGroup.get('imageURL')?.value); // Use the already uploaded image URLs
      } else {
        uploadedImages.push(''); // No image URL
      }
    }
    return uploadedImages;
  }

  resetForm(resetParent: boolean = true): void {
    this.componentForm.reset({
      component: {
        componentId: 0,
        title: '',
        subtitle: '',
        description: '',
        infoTitle: '',
        redirectUrl: '',
        urlName: '',
        infoDescription: '',
        componentTypeId: resetParent ? 0 : this.selectedParentId,
        status: 0,
        position: 0,
        itemQuantity: 0,
        itemListId: 0,
        itemSort: 0,
        categoryId: 0,
        subCategoryId: 0,
        subSubCategoryId: 0,
        collectionId: 0
      },
      infoFields: []
    });
  
    // Clear form arrays
    this.infoFields.clear();
  
    // Reset selection variables
    this.selectedOption = '';
    this.selectedDesignDescription = '';
    this.selectedDesignImageUrl = '';
    this.selectedDesign = null;
    this.minItems = 0;
    this.maxItems = 0;
    this.errorMessage = '';
    if (resetParent) {
      this.selectedParentId = 0;
    }
    this.categoryId = 0;
    this.subCategoryId = 0;
    this.subSubCategoryId = 0;
    this.collectionId = 0;

    this.getCategories();
    this.getCollections();
    this.getSubCategories();
    this.getSubSubCategories();
  
    this.cdr.detectChanges(); // Detectar cambios después de resetear el formulario
  }
  
  
  closeModal(): void {
    $('#successcomponentModal').modal('hide');
    $('#errorcomponentModal').modal('hide');
    $('#errorMaxMinItemModal').modal('hide');
  }
}
