import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';

declare let $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  categories: any[] = [];
  loading: boolean = false;
  deleting: boolean = false;
  searchString: string = '';
  modalDelete = false;
  categoryIdToDelete: any;
  showCreateCategoryModal = false;
  showEditCategoryModal = false;
  categoryIdForSubCategories: string | null = null;
  categoryIdForSubSubCategories: string | null = null;
  selectedCategoryId!: string;
  timekitResources: any;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    public router: Router,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }

  async getCategories() {
    this.loading = true;
    await this.apiService.getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
        this.timekitResources = data.timekitResources;
        this.categories.forEach(category => {
          const resource = this.timekitResources.find((r: { timekitResourceId: any; }) => r.timekitResourceId === category.timekitResourceId);
          category.resourceName = resource ? resource.resourceName : 'No Resource'; // Agregar resourceName
        });
      });
    this.loading = false;
  }

  showSubCategories(categoryId: string) {
    this.categoryIdForSubCategories = categoryId;
    this.categoryIdForSubSubCategories = null;
  }

  showSubSubCategories(categoryId: string) {
    this.categoryIdForSubSubCategories = categoryId;
    this.categoryIdForSubCategories = null;
  }

  backToCategories() {
    this.categoryIdForSubCategories = null;
    this.categoryIdForSubSubCategories = null;
  }

  setEditingCategory(categoryId: string) {
    this.settingsService.setEditingCategory(categoryId);
  }

  async deleteCategory(response: number) {
    if (response && this.categoryIdToDelete !== null) {
      this.loading = true;
      try {
        const category = this.categories.find(cat => cat.categoryId === this.categoryIdToDelete);
        if (category && category.categoryImageUrl) {
          await this.deleteImage(category.categoryImageUrl);
        }
        await this.apiService.deleteCategory(this.themingService.shopId, this.categoryIdToDelete);
        this.getCategories();
        $('#deleteCategoryModal').modal('show');
      } catch (error) {
        console.error('Error deleting category:', error);
      } finally {
        this.loading = false;
        this.modalDelete = false;
      }
    } else {
      this.modalDelete = false;
    }
  }

  async deleteImage(imageUrl: string) {
    try {
      await this.apiService.deleteFile(imageUrl);
      console.log('Image deleted successfully');
    } catch (error) {
      console.log('Error deleting image:', error);
    }
  }

  closeModal() {
    $('#deleteCategoryModal').modal('hide');
  }

  openCreateCategoryModal() {
    this.showCreateCategoryModal = true;
  }

  closeCreateCategoryModal() {
    this.showCreateCategoryModal = false;
  }

  openEditCategoryModal(categoryId: string) {
    this.selectedCategoryId = categoryId;
    this.showEditCategoryModal = true;
  }

  handleCategoryUpdated() {
    this.showEditCategoryModal = false;
    this.getCategories();
  }

  closeEditCategoryModal() {
    this.showEditCategoryModal = false;
  }

  handleCategoryCreated() {
    this.showCreateCategoryModal = false;
    this.getCategories();
  }
}
